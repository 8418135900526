<template>
  <v-container
      fluid
      class="fill-height justify-center"
      v-bind:style="backgroundDefault"

  >
    <v-row  class="d-flex flex-column fill-height mb-14">
      <div class="pa-md-2 mb-14 fill-height imgagenHidden">
        <v-layout v-if="this.$store.state.catalogToken != ''" class="ma-0 pa-0">
          <v-app-bar rounded class="justify-center" v-bind:style="catalogBgFolorTitle">
            <v-app-bar-title class="mt-5 pt-0">
              <p
                  style="width: 300px"
                  v-html="
                  this.$appConfig.homeTitle.find(
                    (_) => _.culture === $i18n.locale
                  ).name
                "
              ></p>
            </v-app-bar-title>
            <v-spacer></v-spacer>

            <v-select
                v-show="$appConfig.langs.length > 1"
                v-if="$vuetify.breakpoint.smAndDown ? false : true"
                class="mt-7 pt-0"
                background-color="primary"
                outlined
                dense
                :rounded="$appConfig.textbox.rounded"
                dark
                style="width: 100px; max-width: 200px"
                :items="$appConfig.langs"
                item-text="name"
                item-value="id"
                v-model="$i18n.locale"
            >
              <template slot="prepend-inner">
                <v-icon>mdi-earth</v-icon>
              </template>
            </v-select>

            <div
                class="pr-4"
                v-if="$vuetify.breakpoint.smAndDown ? true : false"
                v-show="$appConfig.langs.length > 1"
            >
              <v-menu top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      fab
                      small
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon>mdi-earth</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                      v-for="(item, id) in $appConfig.langs"
                      :key="id"
                      @click="langClick(item.id)"
                  >
                    <v-list-item-title style="cursor: pointer">{{
                        item.name
                      }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-app-bar>
        </v-layout>

        <v-layout
            row
            wrap
            class="d-flex flex-column ml-0 mr-0 mt-4 pa-md-2 fill-height"
            v-bind:style="catalogBgFolor"
        >
          <v-btn
              class="justify-center ma-4"
              small
              color="primary"
              :rounded="
              $appConfig.hasOwnProperty('button')
                ? $appConfig.button.rounded
                : false
            "
              width="120px"
              @click="goBack"
          >
            <v-icon left class="ml-0"> mdi-arrow-left-circle</v-icon>
            {{ $t("common.goBack") }}
          </v-btn>

          <v-card class="ma-md-2">
            <v-container id="screenPrincipal" fluid class="d-flex flex-row">
              <v-row>
                <v-col class="ordenColumImg" >
                  <v-img  id="photoProduct"
                        max-width="50%"  
                        style="margin: 0 auto"            
                       cover
                      v-bind:src="selectedProduct.imageUrl"
                  >
                  </v-img>
                  <v-card-title
                      class="justify-center primary--text example"
                      v-if="selectedProduct.name !== undefined"
                  >
                    {{
                      selectedProduct.name.length > 25
                          ? selectedProduct.name.slice(0, 25) + "..."
                          : selectedProduct.name
                    }}
                  </v-card-title>
                  <v-card-text>
                    {{
                     selectedProduct.description
                    }}
                  </v-card-text
                  >
                </v-col>

                <v-col
                    wrap
                    align="center"
                    justify="center"
                    class="justify-center ordenColum"
                    v-if="this.$store.state.code != ''"
                >
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card-title class="justify-center primary--text">
                      {{ $t("redeem.title") }}
                    </v-card-title>
                    <v-expansion-panels v-model="panel">

                      <v-expansion-panel
                          v-model="panel"
                          expand
                          v-if="this.showComboboxBilling == true|| this.showComboboxSize == true"
                          ref="epanelDeliveryInfo"
                          @click="
                          $vuetify.goTo($refs.epanelDeliveryInfo, {
                            duration: 1000,
                            offset: 0,
                            easing: 'easeInOutCubic',
                          })
                        "
                      >
                        <v-expansion-panel-header disable-icon-rotate>
                          {{ $t("redeem.shippingData") }}
                          <template v-slot:actions>
                            <v-icon
                                color="teal"
                                v-if="validshippingData == true"
                            >
                              mdi-check
                            </v-icon>
                            <v-icon
                                color="error"
                                v-if="validshippingData == false"
                            >
                              mdi-alert-circle
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                           <v-combobox   v-if="this.showComboboxBilling == true"
                              ref="codeBillingField"
                              :items="billing"
                              :return-object="true"
                              :multiple="false"                             
                              item-text="businessName"
                              item-value="code"                             
                              :label="$t('redeem.billingLabel')"   
                              :rules="requiredRules" 
                              @change="onChangeBilling($event)"                 
                          ></v-combobox>

                          <v-combobox   v-if="this.showComboboxSize == true"
                              ref="filteredCatalog"
                              :items="filteredCatalog"
                              :return-object="true"
                              :multiple="false"                          
                              item-text="name"
                              item-value="clientProductCode"                             
                              :label="$t('redeem.sizes')"    
                              :rules="requiredRules"
                              @change="onChangefilteredCatalog($event)"                 
                          ></v-combobox>

                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel
                          expand
                          v-model="panel"
                          ref="epanelPersonalData"
                          @click="
                          $vuetify.goTo($refs.epanelPersonalData, {
                            duration: 1000,
                            offset: 0,
                            easing: 'easeInOutCubic',
                          })
                        "
                      >
                        <v-expansion-panel-header disable-icon-rotate>
                          {{ $t("redeem.personalData") }}
                          <template v-slot:actions>
                            <v-icon
                                color="teal"
                                v-if="validPersonalData == true"
                            >
                              mdi-check
                            </v-icon>
                            <v-icon
                                color="error"
                                v-if="validPersonalData == false"
                            >
                              mdi-alert-circle
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-text-field
                              ref="dniField"
                              v-model="redeemRequest.IdCardNumber"
                              :rules="dniRules"
                              :label="$t('redeem.dniPassport')"
                              :required="getCatalogFields().dni"
                              v-if="getCatalogFields().dni"
                          ></v-text-field>
                          <v-text-field
                              ref="nameField"
                              v-model="redeemRequest.Name"
                              :counter="40"
                              :rules="nameRules"
                              :label="$t('redeem.nameLabel')"
                              :required="getCatalogFields().name"
                              v-if="getCatalogFields().name"
                          ></v-text-field>
                          <v-text-field
                              ref="lastNameField"
                              v-model="redeemRequest.LastName"
                              :counter="100"
                              :rules="lastNameRules"
                              :label="$t('redeem.lastNameLabel')"
                              :required="getCatalogFields().surname"
                              v-if="getCatalogFields().surname"
                          ></v-text-field>
                          <v-text-field
                              ref="emailConfirmField"
                              v-model="emailConfirm"
                              @paste.prevent
                              @copy.prevent
                              @blur=" checkPersonalData"
                              :rules="emailRules"
                              :label="$t('redeem.emailLabel')"
                              required
                          ></v-text-field>
                          <v-text-field
                              ref="emailField"
                              v-model="redeemRequest.Email"
                              @paste.prevent
                              @copy.prevent
                              :rules="emailRulesRepeat"
                              :label="$t('redeem.repeatEmailLabel')"
                              required
                          ></v-text-field>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel
                          v-model="panel"
                          v-if="this.selectedProduct.isVoucher == false /*&& this.anyFieldInPanelDeliveryInfo()*/"
                          ref="epanelDeliveryInfo"
                          @click="
                          $vuetify.goTo($refs.epanelDeliveryInfo, {
                            duration: 1000,
                            offset: 0,
                            easing: 'easeInOutCubic',
                          })
                        "
                      >
                        <v-expansion-panel-header disable-icon-rotate>
                          {{ $t("redeem.deliveryData") }}
                          <template v-slot:actions>
                            <v-icon
                                color="teal"
                                v-if="validDeliveryData"
                            >
                              mdi-check
                            </v-icon>
                            <v-icon
                                color="error"
                                v-if="!validDeliveryData"
                            >
                              mdi-alert-circle
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                          <v-select v-if="autoFillCenters"
                                    ref="centerField"
                                    :items="centers"
                                    :label="$t('redeem.centersLabel')"
                                    :item-value="selectedCenter"
                                    :rules="autoFillCenters ? requiredRulesCenter : true"
                                    @change="onChangeCenter($event)"
                          >
                            <template v-slot:item="slotProps">
                              {{ slotProps.item.centerName }}
                            </template>
                            <template v-slot:selection="selectedProps">
                              {{ selectedProps.item.centerName }}
                            </template>
                          </v-select>

                          <v-select
                              :disabled="autoFillCenters"
                              ref="countryField"
                              v-model="redeemRequest.Address.Country"
                              :items="defaultCountries"
                              :label="$t('redeem.countryLabel')"
                              item-value="name"
                              item-text="name"
                              :rules="autoFillCenters ? true : requiredRules"
                              :required="getCatalogFields().country"
                              v-if="getCatalogFields().country"
                          >
                            <template v-slot:item="slotProps">
                              <i
                                  :class="['mr-2', 'em', slotProps.item.flag]"
                              ></i>
                              {{ slotProps.item.name }}
                            </template>
                          </v-select>

                          <v-autocomplete
                              ref="cityField"
                              :items="cities"
                              item-text="name"
                              item-value="name"
                              v-model="redeemRequest.Address.City"
                              :label="$t('redeem.cityLabel')"
                              :rules="autoFillCenters ? true : cityRules"
                              :disabled="autoFillCenters"
                              :required="getCatalogFields().province"
                              v-if="getCatalogFields().province"
                          ></v-autocomplete>

                          <v-combobox
                              ref="townField"
                              :items="towns"
                              :return-object="false"
                              :multiple="false"
                              :counter="60"
                              item-text="name"
                              item-value="name"
                              v-model="redeemRequest.Address.Town"
                              :label="$t('redeem.townLabel')"
                              :rules="autoFillCenters ? true : townRules"
                              :disabled="autoFillCenters"
                              :required="getCatalogFields().city"
                              v-if="getCatalogFields().city"
                          ></v-combobox>

                          <v-text-field v-if="autoFillCenters && getCatalogFields().postalCode"
                                        ref="postalCodeField"
                                        v-model="redeemRequest.Address.PostalCode"
                                        :label="$t('redeem.postalCodeLabel')"
                                        :rules="autoFillCenters ? true : requiredRules"
                                        :disabled="autoFillCenters"
                                        :required="getCatalogFields().postalCode"
                          ></v-text-field>
                          <v-text-field-integer v-else-if="getCatalogFields().postalCode"
                                                ref="postalCodeField"
                                                v-model="redeemRequest.Address.PostalCode"
                                                :label="$t('redeem.postalCodeLabel')"
                                                :rules="autoFillCenters ? true : requiredRules"
                                                v-bind:properties="postalCodeProperties"
                                                v-bind:options="postalCodeOptions"
                                                v-bind:focus="focus"
                                                v-on:focus="focus = false"
                                                :disabled="autoFillCenters"
                          ></v-text-field-integer>

                          <v-text-field
                              ref="streetField"
                              v-model="redeemRequest.Address.Street"
                              :rules="autoFillCenters ? true : commentsRules"
                              :counter="80"
                              :label="$t('redeem.streetLabel')"
                              :disabled="autoFillCenters"
                              :required="getCatalogFields().address"
                              v-if="getCatalogFields().address"
                          ></v-text-field>
                          <vue-tel-input-vuetify
                              v-if="this.selectedProduct.isVoucher == false && getCatalogFields().phone"
                              :preferredCountries="['es', 'pt']"
                              :label="$t('redeem.phoneLabel')"
                              placeholder=""
                              :valid-characters-only="true"
                              :rules="phoneRules"
                              :defaultCountry="defaultCountryPhone"
                              @input="onPhoneInput"
                              :required="getCatalogFields().phone"
                          />
                          <div class="pruebaDataProtection"
                            v-if="getCatalogFields().hasText"
                            :style="cssVars"
                            v-html="getText()"
                          ></div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel
                          v-model="panel"
                          v-if="this.selectedProduct.isVoucher == false"
                          ref="epanelCommentInfo"
                          @click="
                          $vuetify.goTo($refs.epanelCommentInfo, {
                            duration: 1000,
                            offset: 0,
                            easing: 'easeInOutCubic',
                          })
                        "
                      >
                        <v-expansion-panel-header  disable-icon-rotate>
                          {{ mandatoryComments ? $t("redeem.instructions") : $t("redeem.remarks") }}
                          <template v-slot:actions>
                            <v-icon
                                color="teal"
                                v-if="validCommentData == true && mandatoryComments == true"
                            >
                              mdi-check
                            </v-icon>
                            <v-icon
                                color="error"
                                v-if="validCommentData == false && mandatoryComments == true"
                            >
                              mdi-alert-circle
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-sheet
                              v-if="mandatoryComments == true"
                              outlined
                              elevation="2"
                              class="text-left pa-2 font-weight-bold text-caption text--secondary">{{ campaignInstructions }}</v-sheet>
                          <v-textarea class="mt-5"
                                      ref="commentsField"
                                      :rules="mandatoryComments ? commentsRules : true"
                                      v-model="redeemRequest.Address.Comments"
                                      :counter="100"
                                      :label="$t('redeem.comments')"
                                      dense
                          ></v-textarea>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    
                    <v-card-text id="cardPrivacyPolicy">
                      <div class="pruebaDataProtection"
                          v-for="link in this.$appConfig.links.filter(
                            (_) => _.linkId === 'DataProtection'
                          )"
                          :key="link.linkId"
                          @click="handleClick"
                          :style="cssVars"
                          v-html="getdataprotection(link.linkId)"
                      ></div>
                    </v-card-text>

                    <v-checkbox
                        v-if="showGeneralConditions == true"
                        class="ma-0 pt-4"
                        v-model="redeemRequest.AcceptGeneralConditions"
                        :rules="requiredRules"
                        required
                    >
                      <span class="flex align-center" slot="label" @click.stop>
                        <b> {{ $t("redeem.accept") }}</b>

                        <v-btn
                            text
                            small
                            color="primary"
                            @click="openFooter(link.linkId)"
                            v-for="link in this.$appConfig.links.filter(
                            (_) => _.redeem === true && _.linkId === 'GeneralConditions'
                          )"
                            :key="link.linkId"
                        >
                          {{
                            link.linkName.find(
                                (_) => _.culture === $i18n.locale
                            ).name
                          }}
                        </v-btn>
                      </span>
                    </v-checkbox>

                    <v-checkbox
                        class="ma-0 pt-4"
                        v-if="showPrivacyPolicy == true"
                        v-model="redeemRequest.AcceptComunications"
                        :rules="requiredRules"
                        required
                    >
                      <span class="flex align-center" slot="label" @click.stop>
                        <b> {{ $t("redeem.accept") }}</b>

                        <v-btn
                            text
                            small
                            color="primary"
                            @click="openFooter(link.linkId)"
                            v-for="link in this.$appConfig.links.filter(
                            (_) => _.redeem === true && _.linkId === 'PrivacyPolicy'
                          )"
                            :key="link.linkId"
                        >
                          {{
                            link.linkName.find(
                                (_) => _.culture === $i18n.locale
                            ).name
                          }}
                        </v-btn>
                      </span>
                    </v-checkbox>


                    <v-card-actions>
                      <v-btn
                          ref="confirmBtn"
                          class="justify-center"
                          small
                          color="primary"
                          :disabled="sendbtnDisabled"
                          :rounded="
                          $appConfig.hasOwnProperty('button')
                            ? $appConfig.button.rounded
                            : false
                        "
                          @click="validate"
                      >
                        <v-icon left class="ml-0"> mdi-check</v-icon>
                        {{ $t("redeem.confirmButtonLabel") }}
                      </v-btn>
                    </v-card-actions>

                    

                    <v-dialog v-model="dialog" width="500" scrollable>
                      <v-card>
                        <v-card-title>
                          {{ currentFooter.linkName }}
                        </v-card-title>

                        <v-card-text>
                          <div
                              class="ql-editor"
                              v-html="currentFooter.linkContent"
                          ></div>
                        </v-card-text>
                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" text @click="dialog = false">
                            {{ $t("common.close") }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-form>
                </v-col>
                <v-progress-linear
                    indeterminate
                    rounded
                    height="6"
                    color="primary"
                    :active="isLoading"
                >
                </v-progress-linear>
              </v-row>
            </v-container>
          </v-card>
        </v-layout>
      </div>
    </v-row>
    <FlashMessage :position="'right top'"></FlashMessage>
  </v-container>
</template>
<script>
import axios from "axios";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "/src/assets/main.css";
import $ from "jquery";

export default {
  data() {
    return {
      panel: 0,
      panelPersonalClose: false,
      panelDeliveryClose: false,
      sendbtnDisabled: false,
      showComboboxBilling:false,
      showComboboxSize:false,
      selectedProduct: {
        productCode: "",
        orderProductCode: "",
        clientProductCode: "",
        productSetId: "",
        name: "",
        description: "",
        familyId: "",
        stock: 0,
        points: 0,
        imageUrl: "",
        isVoucher: null,
      },
      valid: false,
      showGeneralConditions: false,
      showPrivacyPolicy: false,
      redeemRequest: {
        IdCardNumber: "",
        Name: "",
        LastName: "",
        Email: "",
        Code: "",
        Phone: "",
        AcceptGeneralConditions: false,
        AcceptComunications: false,
        IsVoucher: null,
        CampaignKey: "",
        ProductSetId: "",
        ProductCode: "",
        OrderProductCode: "",
        ProductName: "",
        Address: {
          PostalCode: "",
          Street: "",
          City: "",
          Town: "",
          Country: "",
          CountryCode: "",
          Comments: "",
          CenterCode: "",
          CenterName: "",
          HasText: false,
          Text: ""
        },
        BusinessName: "",
        CodeBilling:"",
        BillingTypeCode:"",
        BillingType: {
          active: false
        },
        BillingNav: {
          BillingTypeCode:"",          
          BusinessName: "",
          Cif: "",
          Description: "",
          Phone: "",
          Country: "",
          Province: "",
          City: "",
          Address: "",
          Postcode: "",
        },
        ProductCodeSon: null,
        ProductNameSon:null,
        orderProductCodeSon:null,
        HasText: false,
        HasPhone: false,
      },
      countries: [],
      defaultCountries: [],
      cities: [],
      towns: [],
      currentCountryItem: null,
      postalCodeProperties: null,
      postalCodeOptions: {
        inputMask: "",
        outputMask: "",
        empty: null,
        applyAfter: false,
        alphanumeric: true,
        lowerCase: false,
      },
      getOrderColumn: "1",
      getOrderColumnImagen: "2",
      emailConfirm: "",
      requiredRules: [
        (v) => !!v || this.$t("common.required")
      ],
      requiredRulesCenter: [
        (v) => !!v || this.$t("common.required")
      ],
      nameRules: [
        (v) => !!v || this.$t("common.required"),
        (v) => (v && v.length <= 40) || this.$t("redeem.InvalidCount"),
      ],
      lastNameRules: [
        (v) => !!v || this.$t("common.required"),
        (v) => (v && v.length <= 100) || this.$t("redeem.InvalidCount"),
      ],
      phoneRules: [
        (v) => !!v || this.$t("common.required"),
        () => this.phoneValid || this.$t("common.invalidPhone"),
      ],
      townRules: [
        (v) => !!v || this.$t("common.required"),
        (v) => (v && v.length <= 60) || this.$t("redeem.InvalidCount"),
      ],
      cityRules: [
        (v) => !!v || (this.redeemRequest.Address.Country ? this.$t("common.required") : this.$t("redeem.selectCountryBeforeCity"))
      ],
      addressRules: [
        (v) => !!v || this.$t("common.required"),
        (v) => (v && v.length <= 80) || this.$t("redeem.InvalidCount"),
      ],
      commentsRules: [
        (v) => (v.length >= 1) || this.$t("redeem.InvalidCount"),
        (v) => (v.length <= 100) || this.$t("redeem.InvalidCount"),
      ],
      focus: false,
      phoneValid: false,
      defaultCountryPhone: "es",
      validPersonalData: false,
      validDeliveryData: false,
      validCommentData: false,
      validshippingData: false,
      currentFooter: {
        linkId: "",
        linkName: "",
        linkUrl: "",
        linkContent: "",
        privacyPolicy: "",
      },
      centers: [],
      autoFillCenters: false,
      campaignInstructions: null,
      mandatoryComments: false,
      selectedCenter: null,
      dialog: false,
      isLoading: false,
      billing: [],
      autoFillBilling: false,
      selectedBilling: null,
      catalog: [],
      filteredCatalog: [],
    };
  },
  created() {
    window.addEventListener('resize', this.funGetOrderCol);    
  },
  destroyed() {
    window.removeEventListener('resize', this.getImagen);
  },
  beforeMount() {
    this.loadData();
    this.GetCatalog();
    this.getCountries();    
    this.getCenters();
    this.getBilling();
    this.showCheckBox();    
    this.funGetOrderCol();
    this.sendbtnDisabled = false;
    this.links = this.$appConfig.links;
  },
  mounted() {
    this.funGetOrderCol();
    this.showEmail();
  },
  methods: {
    
    showBilling(){
      if (this.getCatalogFields().hasText && !this.selectedProduct.isVoucher) {
        this.panel = 1;
        return;
      }
      let panel = this.selectedProduct.isVoucher ? 1 : 3;
      this.panel = panel;
      if (!this.showComboboxSize && !this.showComboboxBilling) { 
        this.panel = 0;
      }
    },

    showEmail(){      
      if (this.$appConfig.showEmailDefault != null || this.$appConfig.showEmailDefault != undefined) {
          if (this.$appConfig.showEmailDefault == true) {
            if (this.$appConfig.emailDefault != null || this.$appConfig.emailDefault != undefined) {             
              this.emailConfirm = this.$appConfig.emailDefault;
              this.redeemRequest.Email = this.$appConfig.emailDefault;
            }  
          } 
      }
    },
    handleClick(e) {
      const elt = e.target.closest("#linkDataProtection");
      if (elt) {
        this.openFooter('PrivacyPolicy')
      }
    },
    redirect(link) {
        window.open(link, '_blank');
      },
    funGetOrderCol(){
      this.getOrderColumn = window.innerWidth >= 1024 && window.innerWidth < 2000 ? "1" : "2"
      this.getOrderColumnImagen = window.innerWidth <= 1024 ? "1" : "2" 
     },   
    getCountries() {
      const self = this;
      $.getJSON(
        process.env.VUE_APP_CONFIG_FILE_PATH + "default_config/countries.json",
          function (json) {
            self.countries = json;           
            if (self.$appConfig.defaultCountries === undefined ) {
              self.defaultCountries  = self.countries      
              }
            else {
              self.defaultCountries  = self.countries.filter(x => self.$appConfig.defaultCountries.find(_ => x.code == _.code ))
           }
          }
      );
      this.GetFingerPrint();
      this.CheckStoredData();
    },
    loadData() {
      this.selectedProduct = this.$store.state.selectedProduct;
    },
    async GetFingerPrint() {
      // Initialize an agent at application startup.
      const fpPromise = FingerprintJS.load();

      (async () => {
        // Get the visitor identifier when you need it.
        const fp = await fpPromise;
        const result = await fp.get();

        // This is the visitor identifier:
        const visitorId = result.visitorId;

        this.fingerPrint = visitorId;
      })();
    },
    async CheckStoredData() {
      if (this.$store.state.code == "") {
        this.$router.push("Home");
      }
    },
    customFilter(item, queryText) {
      const text = item.toLowerCase();

      const searchText = queryText.toLowerCase();

      return text.indexOf(searchText) > -1 || text.indexOf(searchText) > -1;
    },
    onPhoneInput(formattedNumber, { valid, number }) {
      this.phoneValid = !this.getCatalogFields().phone || valid;
      this.redeemRequest.Phone = number.international;
    },
    validateField() {
      this.$refs.form.validate();
    },
    validate() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.sendbtnDisabled = true;
        this.redeemRequest.IsVoucher = this.selectedProduct.isVoucher;

        this.redeemRequest.Code = this.$store.state.code;
        this.redeemRequest.CampaignKey = this.$campaignKey;
        this.redeemRequest.ProductSetId = this.selectedProduct.productSetId;
        this.redeemRequest.ProductCode = this.selectedProduct.productCode;
        this.redeemRequest.OrderProductCode = this.selectedProduct.orderProductCode;
        this.redeemRequest.ProductName = this.selectedProduct.name;

        if(this.redeemRequest.ProductCodeSon != null) {
          this.redeemRequest.ProductCode = this.redeemRequest.ProductCodeSon;
        }

        if(this.redeemRequest.ProductNameSon != null) {
          this.redeemRequest.ProductName = this.redeemRequest.ProductNameSon;
        }
        if(this.redeemRequest.orderProductCodeSon != null ) {
          this.redeemRequest.OrderProductCode = this.redeemRequest.orderProductCodeSon;
        }
        if (this.currentCountryItem != null) {
          this.redeemRequest.Address.CountryCode = this.currentCountryItem.code;
        }
        this.redeemRequest.Address.HasText = this.getCatalogFields().hasText;
        this.redeemRequest.HasText = this.getCatalogFields().hasText;
        if (this.redeemRequest.Address.HasText) {
          this.redeemRequest.Address.Text = this.getText();
        }
        
        this.redeemRequest.HasPhone = this.getCatalogFields().phone;
        this.post(this.redeemRequest);
      }
    },
    reset() {
      this.valid = false;
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    isEmptyOrSpaces(str) {
      return str === null || str.match(/^ *$/) !== null;
    },
    goBack() {
      this.$router.back();
    },
    async post(item) {
      this.isLoading = true;
      this.$recaptcha("Redeem").then((token) => {
        axios
            .post(this.apiUrl, item, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Campaign-Key": this.$campaignKey,
                "Accept-Language": this.$i18n.locale,
                "Finger-Print": this.fingerPrint,
                "sender-id": this.$uuid,
                "Content-Type": "application/json",
                "Recaptcha-Token": token,
                "x-functions-key":
                process.env.VUE_APP_API_REDEEMREQUEST_FUNCTION_KEY,
                "ai-operation-id":
                this.$appInsights.context.telemetryTrace.traceID,
                "ai-session-id":
                this.$appInsights.context.sessionManager.automaticSession.id,
              },
            })
            .then(() => {
              this.$store.dispatch("updateCatalogToken", "");
              this.$appInsights.trackEvent({
                name: "frontend-redeemrequest-success",
                properties: {
                  fingerprint: this.fingerPrint,
                },
              });
              this.isFKPresent();
              this.$router.push("redeemconfirm");
            })
            .catch((error) => {
              this.sendbtnDisabled = false;
              if (error.response) {
                this.$appInsights.trackEvent({
                  name: "frontend-redeemrequest-success",
                  properties: {
                    fingerprint: this.fingerPrint,
                    status: error.response.status,
                  },
                });
                if (error.response.data) {
                  switch (error.response.status) {
                    case 409:
                    case 404:
                      error.response.data.forEach((element) => {
                        this.flashMessage.info({
                          title: this.$t("common.crudinfo"),
                          message: element.value,
                          contentClass: "customMsgContentClass",
                        });
                      });
                      break;
                    case 423:
                      error.response.data.forEach((element) => {
                        this.flashMessage.warning({
                          title: this.$t("common.crudinfo"),
                          message: element.value,
                          contentClass: "customMsgContentClass",
                        });
                      });
                      break;
                    default:
                      error.response.data.forEach((element) => {
                        this.flashMessage.error({
                          title: this.$t("common.cruderror"),
                          message: element.value,
                          contentClass: "customMsgContentClass",
                        });
                      });
                      break;
                  }
                } else {
                  if (error.response.data) {
                    error.response.data.forEach((element) => {
                      this.flashMessage.error({
                        title: this.$t("common.crudinfo"),
                        message: element.value,
                        contentClass: "customMsgContentClass",
                      });
                    });
                  }
                }
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
      });
    },
  async getCenters() {
      this.isLoading = true;
     await this.$recaptcha("Redeem").then((token) => {
        axios.get(this.centersUrl, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Campaign-Name": this.$campaignName,
            "Accept-Language": this.$i18n.locale,
            "Finger-Print": this.fingerPrint,
            "sender-id": this.$uuid,
            "Content-Type": "application/json",
            "Recaptcha-Token": token,
            "x-functions-key":
            process.env.VUE_APP_API_CENTER_FUNCTION_KEY,
            "ai-operation-id":
            this.$appInsights.context.telemetryTrace.traceID,
            "ai-session-id":
            this.$appInsights.context.sessionManager.automaticSession.id,
          },
        })
            .then((response) => {
              this.centers = response.data.centers;
              this.autoFillCenters = response.data.centers.length > 0;
              this.campaignInstructions = response.data.campaignInstructions;
              //this.mandatoryComments = this.isEmptyOrSpaces(this.campaignInstructions);
              this.mandatoryComments = !!this.campaignInstructions;
            })
            .catch((error) => {
              if (error.response) {
                this.$appInsights.trackEvent({
                  name: "frontend-redeemrequest-success",
                  properties: {
                    fingerprint: this.fingerPrint,
                    status: error.response.status,
                  },
                });
                if (error.response.data) {
                  switch (error.response.status) {
                    case 409:
                    case 404:
                      error.response.data.forEach((element) => {
                        this.flashMessage.info({
                          title: this.$t("common.crudinfo"),
                          message: element.value,
                          contentClass: "customMsgContentClass",
                        });
                      });

                      break;
                    case 423:
                      error.response.data.forEach((element) => {
                        this.flashMessage.warning({
                          title: this.$t("common.crudinfo"),
                          message: element.value,
                          contentClass: "customMsgContentClass",
                        });
                      });
                      break;
                    default:
                      error.response.data.forEach((element) => {
                        this.flashMessage.error({
                          title: this.$t("common.cruderror"),
                          message: element.value,
                          contentClass: "customMsgContentClass",
                        });
                      });
                      break;
                  }
                } else {
                  if (error.response.data) {
                    error.response.data.forEach((element) => {
                      this.flashMessage.error({
                        title: this.$t("common.crudinfo"),
                        message: element.value,
                        contentClass: "customMsgContentClass",
                      });
                    });
                  }
                }
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
      });
    },


    async getBilling() {

      this.isLoading = true;
     await this.$recaptcha("Redeem").then((token) => {
        axios.get(this.billingUrl, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Campaign-Name": this.$campaignName,
            "Accept-Language": this.$i18n.locale,
            "Finger-Print": this.fingerPrint,
            "sender-id": this.$uuid,
            "Content-Type": "application/json",
            "Recaptcha-Token": token,
            "x-functions-key":
            process.env.VUE_APP_API_BILLING_FUNCTION_KEY,
            "ai-operation-id":
            this.$appInsights.context.telemetryTrace.traceID,
            "ai-session-id":
            this.$appInsights.context.sessionManager.automaticSession.id,
          },
        })
            .then((response) => {
              this.billing = response.data.billing;
              this.autoFillBilling = response.data.billing.length > 0;
              this.showComboboxBilling  = response.data.billing.length > 0;     
              this.showBilling();
           
            })
            .catch((error) => {
              if (error.response) {
                this.$appInsights.trackEvent({
                  name: "frontend-redeemrequest-success",
                  properties: {
                    fingerprint: this.fingerPrint,
                    status: error.response.status,
                  },
                });
                if (error.response.data) {
                  switch (error.response.status) {
                    case 409:
                    case 404:
                      error.response.data.forEach((element) => {
                        this.flashMessage.info({
                          title: this.$t("common.crudinfo"),
                          message: element.value,
                          contentClass: "customMsgContentClass",
                        });
                      });

                      break;
                    case 423:
                      error.response.data.forEach((element) => {
                        this.flashMessage.warning({
                          title: this.$t("common.crudinfo"),
                          message: element.value,
                          contentClass: "customMsgContentClass",
                        });
                      });
                      break;
                    default:
                      error.response.data.forEach((element) => {
                        this.flashMessage.error({
                          title: this.$t("common.cruderror"),
                          message: element.value,
                          contentClass: "customMsgContentClass",
                        });
                      });
                      break;
                  }
                } else {
                  if (error.response.data) {
                    error.response.data.forEach((element) => {
                      this.flashMessage.error({
                        title: this.$t("common.crudinfo"),
                        message: element.value,
                        contentClass: "customMsgContentClass",
                      });
                    });
                  }
                }
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
      });
    },


    async GetCatalog() {
  
      this.isLoading = true;
        this.$recaptcha("GetCatalog").then((token) => {
          axios
            .get(this.apiurlProductSet, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Accept-Language": this.$i18n.locale,
                "Finger-Print": this.fingerPrint,
                "catalog-token": this.$store.state.catalogToken,
                "Recaptcha-Token": token,
                "x-functions-key":
                  process.env.VUE_APP_API_PRODUCTSET_FUNCTION_KEY,
                "ai-operation-id":
                  this.$appInsights.context.telemetryTrace.traceID,
                "ai-session-id":
                  this.$appInsights.context.sessionManager.automaticSession.id,
              },
            })
            .then((response) => {
              this.catalog = response.data;
    
             this.filter();

             //this.autoFillBilling = this.filteredCatalog.length > 0;
             this.showComboboxSize = this.filteredCatalog.length > 0;
   
             this.showBilling();
    
              this.$appInsights.trackEvent({
                name: "frontend-get-catalogtoken-success",
                properties: {
                  fingerprint: this.fingerPrint,
                },
              });
            })
            .catch((error) => {
              if (error.response) {
                this.$appInsights.trackEvent({
                  name: "frontend-get-catalogtoken-success",
                  properties: {
                    fingerprint: this.fingerPrint,
                    status: error.response.status,
                  },
                });
                switch (error.response.status) {
                  case 409:
                  case 404:
                    error.response.data.forEach((element) => {
                      this.flashMessage.info({
                        title: this.$t("common.crudinfo"),
                        message: element.value,
                        contentClass: "customMsgContentClass",
                      });
                    });

                    break;
                  case 423:
                    error.response.data.forEach((element) => {
                      this.flashMessage.warning({
                        title: this.$t("common.crudinfo"),
                        message: element.value,
                        contentClass: "customMsgContentClass",
                      });
                    });

                    break;

                  default:
                    error.response.data.forEach((element) => {
                      this.flashMessage.error({
                        title: this.$t("common.cruderror"),
                        message: element.value,
                        contentClass: "customMsgContentClass",
                      });
                    });
                    break;
                }
              }
              //console.clear();
            })
            .finally(() => {
              this.isLoading = false;
            });
        });    
    },
    filter() {
      this.filteredCatalog = this.catalog.filter(
        (_) =>_.parentProduct == this.selectedProduct.productCode 
      );   

    },
    onChangefilteredCatalog(event){
      const filteredCatalogSon = this.filteredCatalog.find((_) => _.clientProductCode ===event.clientProductCode)
      this.redeemRequest.ProductCodeSon = filteredCatalogSon.productCode;
      this.redeemRequest.ProductNameSon = filteredCatalogSon.name;
      this.redeemRequest.orderProductCodeSon = filteredCatalogSon.orderProductCode; 
    },

    onChangeBilling(event){
      const codeItemBilling = this.billing.find((_) => _.code === event.code)       
      this.redeemRequest.BillingTypeCode = codeItemBilling.code
        this.redeemRequest.BillingNav.BillingTypeCode = codeItemBilling.code;          
        this.redeemRequest.BillingNav.BusinessName = codeItemBilling.businessName;
        this.redeemRequest.BillingNav.Cif = codeItemBilling.cif;         
        this.redeemRequest.BillingNav.Phone = codeItemBilling.phone;
        this.redeemRequest.BillingNav.Country = codeItemBilling.country;
        this.redeemRequest.BillingNav.Province = codeItemBilling.province;
        this.redeemRequest.BillingNav.City = codeItemBilling.city;
        this.redeemRequest.BillingNav.Address = codeItemBilling.address;
        this.redeemRequest.BillingNav.Postcode = codeItemBilling.postcode;            
    },

    onChangeCenter(event) {
      const countryItem = this.countries.find(
          (_) => _.code === event.country
      );
      this.redeemRequest.Address.Country = countryItem.name;
      this.redeemRequest.Address.Street = event.address;
      this.redeemRequest.Address.PostalCode = event.postcode;
      this.redeemRequest.Address.Town = event.city;
      this.redeemRequest.Address.City = event.province;
      this.redeemRequest.Address.CountryCode = event.country;
      this.redeemRequest.Address.CenterCode = event.centerCode;
      this.redeemRequest.Address.CenterName = event.centerName;
    },

    checkShippingData(){
          this.validshippingData = (this.$refs.codeBillingField ? !this.$refs.codeBillingField.hasError: false) || (this.$refs.filteredCatalog ? !this.$refs.filteredCatalog.hasError: false); 
  
    },       
  
    checkPersonalData() {
      this.validPersonalData =
      (this.$refs.dniField ? !this.$refs.dniField.hasError : !this.getCatalogFields().dni) &&
      (this.$refs.nameField ? !this.$refs.nameField.hasError : !this.getCatalogFields().name) &&
      (this.$refs.lastNameField ? !this.$refs.lastNameField.hasError: !this.getCatalogFields().surname) &&
      ((this.emailConfirm === this.redeemRequest.Email) && (this.emailConfirm.length>5))

      if ((this.validPersonalData || this.prueba) &&
          !this.selectedProduct.isVoucher &&
          !this.validDeliveryData &&
          !this.panelPersonalClose) {
        this.panel = 1;
        this.panelPersonalClose = true;
      }
    },
    checkDeliveryData() {
      if (this.getCatalogFields().hasText) {
        this.validDeliveryData = !this.getCatalogFields().phone || this.phoneValid;
        return true;
      }

      let validDeliveryData =
        (this.$refs.countryField ? !this.$refs.countryField.hasError : !this.getCatalogFields().country) &&
        (this.$refs.cityField ? !this.$refs.cityField.hasError : !this.getCatalogFields().city) &&
        (this.$refs.postalCodeField ? !this.$refs.postalCodeField.hasError : !this.getCatalogFields().postalCode) &&
        (this.$refs.townField ? !this.$refs.townField.hasError : !this.getCatalogFields().city) &&
        (this.$refs.streetField ? !this.$refs.streetField.hasError : !this.getCatalogFields().address);

      if( this.$refs.centerField != undefined ) {
        this.validDeliveryData = validDeliveryData && 
          (this.$refs.centerField ? !this.$refs.centerField.hasError : !this.getCatalogFields().center);
      } else if ( this.$refs.centerField == undefined ) {
        this.validDeliveryData = validDeliveryData && (!this.getCatalogFields().phone || this.phoneValid);
      }
    },
    checkCommentData() {
      this.validCommentData =
          (this.$refs.commentsField ? !this.$refs.commentsField.hasError : false);
    },
    langClick(id) {
      this.$i18n.locale = id;
    },
    getdataprotection(id) {
      const linkItem = this.$appConfig.links.find((item) => item.linkId === id);
      const globalizedLink = linkItem.linkUrl.replace(
          "#lang#",
          this.$i18n.locale
      );

      const globalizedName = linkItem.linkName.find(
          (_) => _.culture === this.$i18n.locale
      ).name;

      this.currentFooter.linkId = linkItem.linkId;
      this.currentFooter.linkName = globalizedName;
      this.currentFooter.linkUrl = linkItem.linkUrl;
      this.getHtmlContentPrivacyPolicy(globalizedLink);
      return this.currentFooter.privacyPolicy;
    },
    openFooter(id) {
      const linkItem = this.$appConfig.links.find((item) => item.linkId === id);
      const globalizedLink = linkItem.linkUrl.replace(
          "#lang#",
          this.$i18n.locale
      );
      const globalizedName = linkItem.linkName.find(
          (_) => _.culture === this.$i18n.locale
      ).name;
      this.currentFooter.linkId = linkItem.linkId;
      this.currentFooter.linkName = globalizedName;
      this.currentFooter.linkUrl = linkItem.linkUrl;  
      const linkExternal =  linkItem.linkName.find(
        (_) => _.culture === this.$i18n.locale
      ).linkUrlExternal;


      if(linkItem.urlExternal == true) {   
        this. redirect(linkExternal); 
      }
      else{
      this.getHtmlContent(globalizedLink);
      this.$el.scrollTop = 0;
      this.dialog = true;
    }
    },
    showCheckBox(){
      const linkItemPrivacyPolicy = this.$appConfig.links.find((item) => item.linkId === "PrivacyPolicy");
      const linkItemGeneralConditions = this.$appConfig.links.find((item) => item.linkId === "GeneralConditions"); 
      this.showPrivacyPolicy  = linkItemPrivacyPolicy.redeem == true ? true : false
      this.showGeneralConditions = linkItemGeneralConditions.redeem == true  ? true : false
    },
    async getHtmlContent(url) {
      await axios
          .get(url)
          .then((response) => {
            this.currentFooter.linkContent = response.data;
          })
          .catch(function (err) {
            console.error(err);
          });
    },
    async getHtmlContentPrivacyPolicy(url) {
      await axios
          .get(url)
          .then((response) => {
            this.currentFooter.privacyPolicy = response.data;
          })
          .catch(function (err) {
            console.error(err);
          });
    },
    checkZipCode: function (v) {
      var isValid = false;
      if (this.currentCountryItem?.controlDigitValidation == true) {
        if (this.currentCountryItem?.code == "ES") {
          isValid = v.substring(0, 2) == this.getZipCode;
        } else {
          isValid = true;
        }
      } else {
        isValid = true;
      }
      return isValid;
    },
    getCatalogFields() {

    /*  if (this.selectedProduct.isVoucher) {
        return this.$appConfig.catalogFields.digitalPresent;
      } else {
        return this.$appConfig.catalogFields.physicalPresent;
      }
    */



      if (this.selectedProduct.familyId === "99") {     
        return this.$appConfig.catalogFields.physicalFKPresent;
      } else {
        if (this.selectedProduct.isVoucher) {
          return this.$appConfig.catalogFields.digitalPresent;
        } else {
          return this.$appConfig.catalogFields.physicalPresent;
        }
      }



    },
    isFKPresent(){
     if (this.selectedProduct.familyId === "99") {
        this.selectedProduct.isVoucher = false;   
     }

    },
    anyFieldInPanelPersonalData() {
      return (
          this.getCatalogFields().dni ||
          this.getCatalogFields().name ||
          this.getCatalogFields().lastName
      );
    },
    getText() {
      if (this.getCatalogFields().hasText) {
        return this.getCatalogFields().text.find((_) => _.langId === this.$i18n.locale).message;
      } else {
        return "";
      }
    },
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_HOST + "/RedeemRequest";
    },
    centersUrl() {
      return process.env.VUE_APP_API_HOST + "/Centers";
    },
    apiurlProductSet() {
      return process.env.VUE_APP_API_HOST + "/ProductSet";
    },

    billingUrl() {
      return process.env.VUE_APP_API_HOST + "/Billing";
    },
    backgroundDefault: function () {
      return {
        "background-image":
            "url(" + this.$appConfig.redeemBackGroundImage + " )",
            "background-position": "center 0",
        "background-size": "100%",     
        "background-repeat": "no-repeat",
        "background-attachment": "fixed"       
      };
    },
    changeOrdenColumn: function () {
      return {
        "order": this.getOrderColumn,      
      };
    },
    changeOrdenColumnImagen: function () {
      return {
        "order": this.getOrderColumnImagen,      
      };
    },   

    postalCodeRules() {
      return [
        (v) => !!v || this.$t("common.required"),
        (v) =>
            (v && v.length <= this.currentCountryItem.postalCodeLength) ||
            this.$t("redeem.PostalCodeLengthValidation").replace(
                "#max#",
                this.currentCountryItem.postalCodeLength
            ),
        (v) => (v && this.checkZipCode(v)) || this.$t("redeem.InvalidZipCode"),
      ];
    },
    
    dniRules() {
      const rules = [];
      if (!this.getCatalogFields().dni) {
        return rules;
      }
      const requiredDniRule = (v) => !!v || this.$t("common.required");
      const identificationRules = (v) => {
        const dniRegex = /([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])/g;
        const passportRegex = /^(?!^0+$)[a-zA-Z0-9]{6,9}$/g;
        var invalid = 0;

        if (dniRegex.test(v)) {
          const lengthDniRule = (v && v.length <= 9);
          if (lengthDniRule) {
            return true;
          } else {
            return this.$t("redeem.InvalidDNI");
          }
        } else {
          invalid = 1;
        }

        if (passportRegex.test(v)) {
          return true;
        } else {
          invalid += 2;
        }

        if ( invalid > 0) {
          return this.$t("redeem.InvalidIdentification");
        }
      };
      rules.push(requiredDniRule);
      rules.push(identificationRules);
      return rules;
    },

    emailRules() {

      const rules = []; 
      const requiredRule1 = (v) => !!v || this.$t("common.required");
      rules.push(requiredRule1);

        const requiredRule = (v) => !!v || this.$t("common.required");

        if (this.redeemRequest.Email !== this.emailConfirm){
          const matchRule = (v) =>(!!v && v) === this.redeemRequest.Email || this.$t("redeem.InvalidEmailMatch");
          rules.push(matchRule);
        }

        const emailRule = (v) => /.+@.+\..+/.test(v) || this.$t("redeem.InvalidEmail");
        const lengthRule = (v) => (v && v.length <= 100) || this.$t("redeem.InvalidCount");

        rules.push(requiredRule);    
        rules.push(emailRule);
        rules.push(lengthRule);


      return rules;
    },
    emailRulesRepeat() {
      const rules = [];  
      const requiredRule1 = (v) => !!v || this.$t("common.required");
      rules.push(requiredRule1);
     
        const requiredRule = (v) => !!v || this.$t("common.required");


        if (this.redeemRequest.Email !== this.emailConfirm){
              const matchRule = (v) =>(!!v && v) === this.emailConfirm || this.$t("redeem.InvalidEmailMatch");
               rules.push(matchRule);
            }

        const emailRule = (v) =>
            /.+@.+\..+/.test(v) || this.$t("redeem.InvalidEmail");
        const lengthRule = (v) =>
            (v && v.length <= 100) || this.$t("redeem.InvalidCount");

        rules.push(requiredRule);   
        rules.push(emailRule);
        rules.push(lengthRule);
    
      return rules;
    },
    catalogBgFolor: function () {
      return {
        "border-radius": "5px",
        "background-color": this.$appConfig.transparentBgColor,
      };
    },
    getZipCode() {
      return this.currentCountryItem?.cities.find(
          (_) => _.name == this.redeemRequest.Address?.City
      )?.id;
    },

    cssVars() {
      return {
        '--button-bg-color': this.$appConfig.titleDataProtection,
        '--button-bg-backgroundColor': this.$appConfig.backgroundDataProtection
      };
    },
    catalogBgFolorTitle: function () {
      let result;
      if( this.$appConfig.homeTitle.find((_) => _.culture === this.$i18n.locale).name.length===0)  {
        result =  "background-color: transparent !important"   
       }
      return result;
    }, 
  },
  watch: {
    emailConfirm: {
      immediate: true,
      deep: true,
      handler(val) {
        if(val !== undefined && val !== "")
        {
          this.checkPersonalData();
        }
      }
    },
    redeemRequest: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      deep: true,
      handler(val) {
        if (val.Address.Country !== undefined && val.Address.Country !== "") {
          const countryItem = this.countries.find(
              (_) => _.name === val.Address.Country
          );
          this.cities = countryItem?.cities;
          const currentZipCode = this.currentCountryItem?.cities?.find(
              (_) => _.name == this.redeemRequest.Address?.City
          )?.id;
          this.towns = countryItem?.towns.filter(
              (_) => _.parent == currentZipCode
          );
          if (countryItem.postalCodeMask !== undefined) {
            this.postalCodeOptions = {
              inputMask: countryItem.postalCodeMask,
              outputMask: countryItem.postalCodeMask,
              empty: null,
              applyAfter: false,
              alphanumeric: true,
              lowerCase: false,
            };
          }
          this.postalCodeProperties = {
            prefix: "",
            suffix: "",
            readonly: false,
            disabled: false,
            outlined: false,
            clearable: true,
            placeholder: "",
            counter: countryItem.postalCodeLength,
            rules: this.postalCodeRules,
          };
          this.currentCountryItem = countryItem;
        }
        if (this.$refs.form != undefined) {
          this.$refs.form.validate();
        }
        this.checkCommentData();
        this.checkPersonalData();
        this.checkShippingData();

        this.checkDeliveryData();
      },
    },
  },
};
</script>

<style>
@import "https://emoji-css.afeld.me/emoji.css";
</style>


<style>
    #linkTitulo {
      color: var(--button-bg-color);
      background-color: var(--button-bg-backgroundColor);   
  }
  #linkDataProtection{
    color:#0026ed;
    text-decoration: underline;
  }
</style>
